import { createContext } from 'react';

export interface OfflineContextType {
  isOffline: boolean;
  setIsOffline: (value: boolean) => void;
}

export const OfflineContext = createContext<OfflineContextType>({
  isOffline: false,
  setIsOffline: () => {},
});
