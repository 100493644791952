import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../lib/api';
import { Event } from '../types/index';
import { openDB } from 'idb';

function EventListScreen() {
  const { hubId } = useParams<{ hubId: string }>();
  const navigate = useNavigate();
  const [events, setEvents] = useState<Event[]>([]);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const db = openDB('eventsDB', 1, {
      upgrade(db) {
        db.createObjectStore('events', { keyPath: 'id' });
      },
    });

    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    fetchEvents();

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const fetchEvents = async () => {
    try {
      if (isOnline) {
        const response = await api.get<{ data: Event[] }>(`/hubs/${hubId}/events`);
        const fetchedEvents = response.data.data;
        setEvents(fetchedEvents);
        await saveEventsToIndexedDB(fetchedEvents);
      } else {
        const cachedEvents = await getEventsFromIndexedDB();
        setEvents(cachedEvents);
      }
    } catch (error) {
      console.error('Error fetching events:', error);
      const cachedEvents = await getEventsFromIndexedDB();
      setEvents(cachedEvents);
    }
  };

  const saveEventsToIndexedDB = async (events: Event[]) => {
    const db = await openDB('eventsDB', 1);
    const tx = db.transaction('events', 'readwrite');
    const store = tx.objectStore('events');
    for (const event of events) {
      await store.put(event);
    }
    await tx.done;
  };

  const getEventsFromIndexedDB = async (): Promise<Event[]> => {
    const db = await openDB('eventsDB', 1);
    return db.getAll('events');
  };

  return (
    <div className="container">
      <h1 className="title">Events</h1>
      <ul className="event-list">
        {events.map((item) => (
          <li key={item.id} className="event-item">
            <button
              onClick={() => navigate(`/hubs/${hubId}/events/${item.id}`)}
              className="event-button"
            >
              {item.title}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default EventListScreen;
