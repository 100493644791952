import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../lib/api';
import { Hub } from '../types/index';
import { Button } from '../components/button';


function DashboardScreen() {
  const [hubs, setHubs] = useState<Hub[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchHubs();
  }, []);

  const fetchHubs = async () => {
    try {
      const response = await api.get<{ data: Hub[] }>('/hubs');
      console.log('hubs', response.data.data);
      setHubs(response.data.data);
    } catch (error) {
      console.error('Error fetching hubs:', error);
    }
  };

  return (
    <div>
      <h1>Hubs</h1>
      <ul>
        {hubs.map((hub) => (
          <li key={hub.id}>
            <button onClick={() => navigate(`/hubs/${hub.id}/events`)}>
              {hub.title}
            </button>
          </li>
        ))}
      </ul>
      <Button type="submit">Click me</Button>
    </div>
  );
}

export default DashboardScreen;
