import React, { useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../lib/api';
import { OfflineContext } from '../context/OfflineContext';
import { storeOfflineData, getOfflineData } from '../utils/offlineStorage';
import { WalkInFormData } from '../types/index';

function WalkInScreen() {
  const { eventId, hubId } = useParams<{ eventId: string; hubId: string }>();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<WalkInFormData>({
    email: '',
    firstName: '',
    lastName: '',
    company: '',
    jobTitle: '',
    country: '',
  });
  const { isOffline } = useContext(OfflineContext);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isOffline) {
      const existingWalkIns = await getOfflineData<any[]>('walkIns') || [];
      await storeOfflineData('walkIns', [...existingWalkIns, { ...formData, eventId, timestamp: new Date() }]);
    } else {
      try {
        await api.post(`/hubs/${hubId}/events/${eventId}/walk-in`, formData);
      } catch (error) {
        console.error('Error registering walk-in:', error);
      }
    }
    navigate(-1);
  };

  return (
    <div className="container">
      <h1>Walk-In Registration</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="firstName"
          placeholder="First Name"
          value={formData.firstName}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="lastName"
          placeholder="Last Name"
          value={formData.lastName}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="company"
          placeholder="Company"
          value={formData.company}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="jobTitle"
          placeholder="Job Title"
          value={formData.jobTitle}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="country"
          placeholder="Country"
          value={formData.country}
          onChange={handleInputChange}
        />
        <button type="submit">Register</button>
      </form>
    </div>
  );
}

export default WalkInScreen;
