import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import api from '../lib/api';
import { OfflineContext } from '../context/OfflineContext';
import { storeOfflineData, getOfflineData } from '../utils/offlineStorage';
import { Delegate } from '../types/index';

function SearchScreen() {
  const { eventId, hubId } = useParams<{ eventId: string; hubId: string }>();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [delegates, setDelegates] = useState<Delegate[]>([]);
  const { isOffline } = useContext(OfflineContext);

  const searchDelegates = async () => {
    if (isOffline) {
      const offlineDelegates = await getOfflineData<Delegate[]>('delegates');
      if (offlineDelegates) {
        setDelegates(offlineDelegates.filter(d => 
          d.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          d.email.toLowerCase().includes(searchQuery.toLowerCase())
        ));
      } else {
        setDelegates([]);
      }
    } else {
      try {
        const response = await api.get<Delegate[]>(`/hubs/${hubId}/events/${eventId}/delegates`, {
          params: { search: searchQuery }
        });
        setDelegates(response.data);
        await storeOfflineData('delegates', response.data);
      } catch (error) {
        console.error('Error searching delegates:', error);
      }
    }
  };

  const checkInDelegate = async (delegateId: number) => {
    if (isOffline) {
      const existingCheckIns = await getOfflineData<any[]>('checkIns') || [];
      await storeOfflineData('checkIns', [...existingCheckIns, { delegateId, eventId, timestamp: new Date() }]);
    } else {
      try {
        await api.post(`/hubs/${hubId}/events/${eventId}/delegates/${delegateId}/check-in`);
      } catch (error) {
        console.error('Error checking in delegate:', error);
      }
    }
  };

  return (
    <div className="container">
      <input
        className="search-input"
        type="text"
        placeholder="Search delegates"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onKeyPress={(e) => e.key === 'Enter' && searchDelegates()}
      />
      <ul className="delegate-list">
        {delegates.map((item) => (
          <li key={item.id} className="delegate-item" onClick={() => checkInDelegate(item.id)}>
            {item.name} - {item.email}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SearchScreen;
