import AsyncStorage from '@react-native-async-storage/async-storage';

export const storeOfflineData = async <T>(key: string, value: T): Promise<void> => {
  try {
    const jsonValue = JSON.stringify(value);
    await AsyncStorage.setItem(key, jsonValue);
  } catch (e) {
    console.error('Error storing offline data:', e);
  }
};

export const getOfflineData = async <T>(key: string): Promise<T | null> => {
  try {
    const jsonValue = await AsyncStorage.getItem(key);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    console.error('Error retrieving offline data:', e);
    return null;
  }
};

export const syncOfflineData = async (): Promise<void> => {
  // Implement syncing logic here
  // This should send stored offline data to the server when online
};

