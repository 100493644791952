import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';
import LoginScreen from './screens/LoginScreen';
import DashboardScreen from './screens/DashboardScreen';
import EventListScreen from './screens/EventListScreen';
import EventHomeScreen from './screens/EventHomeScreen';
import SearchScreen from './screens/SearchScreen';
import WalkInScreen from './screens/WalkInScreen';
import { AuthContext, AuthContextType } from './context/AuthContext';
import { OfflineContext, OfflineContextType } from './context/OfflineContext';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const [isOffline, setIsOffline] = useState<boolean>(false);

  useEffect(() => {
    checkLoginStatus();
  }, []);

  const checkLoginStatus = () => {
    const token = localStorage.getItem('userToken');
    setIsLoggedIn(!!token);
  };

  const authContext: AuthContextType = {
    isLoggedIn: isLoggedIn ?? false,
    setIsLoggedIn,
  };

  const offlineContext: OfflineContextType = {
    isOffline,
    setIsOffline,
  };

  if (isLoggedIn === null) {
    return <div>Loading...</div>; // Or a loading spinner
  }

  return (
    <AuthContext.Provider value={authContext}>
      <OfflineContext.Provider value={offlineContext}>
        <Router>
          <AppRoutes isLoggedIn={isLoggedIn} />
        </Router>
        <Analytics />
      </OfflineContext.Provider>
    </AuthContext.Provider>
  );
}

function AppRoutes({ isLoggedIn }: { isLoggedIn: boolean }) {
  const location = useLocation();

  return (
    <Routes>
      {!isLoggedIn ? (
        <Route path="/login" element={<LoginScreen />} />
      ) : (
        <>
          <Route path="/dashboard" element={<DashboardScreen />} />
          <Route path="/hubs/:hubId/events" element={<EventListScreen />} />
          <Route path="/hubs/:hubId/events/:eventId" element={<EventHomeScreen />} />
          <Route path="/hubs/:hubId/events/:eventId/search" element={<SearchScreen />} />
          <Route path="/hubs/:hubId/events/:eventId/walkin" element={<WalkInScreen />} />
        </>
      )}
      <Route
        path="*"
        element={
          isLoggedIn ? (
            location.pathname === '/login' ? (
              <Navigate to="/dashboard" replace />
            ) : (
              <Navigate to={location.pathname} replace />
            )
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
    </Routes>
  );
}

export default App;
