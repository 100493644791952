import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../lib/api';
import { Event } from '../types/index';

function EventHomeScreen() {
  const { eventId, hubId } = useParams<{ eventId: string; hubId: string }>();
  const navigate = useNavigate();
  const [event, setEvent] = useState<Event | null>(null);

  useEffect(() => {
    fetchEventDetails();
  }, []);

  const fetchEventDetails = async () => {
    try {
      const response = await api.get<Event>(`/hubs/${hubId}/events/${eventId}`);
      setEvent(response.data);
    } catch (error) {
      console.error('Error fetching event details:', error);
    }
  };

  return (
    <div className="container">
      {event && (
        <>
          <h1 className="title">{event.title}</h1>
          <p className="description">{event.description}</p>
          <div className="button-container">
            <button onClick={() => {/* Implement QR code scanning */}}>Scan QR Code</button>
            <button onClick={() => navigate(`/search/${hubId}/${eventId}`)}>Search</button>
            <button onClick={() => navigate(`/walkin/${hubId}/${eventId}`)}>Add Walk In</button>
          </div>
        </>
      )}
    </div>
  );
}

export default EventHomeScreen;
